@import './sass-vars.scss';

body {
  font-size: 18px;
  color: var(--light-gray);
  @media ($bp-ml) {
    font-size: 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--titles);
}

h1, h2 {
  @media ($bp-sm) {
    text-align: center;
  }
}

h1 {
  font-size: 48px;
  margin-top: 32px;
  margin-bottom: 48px;
  @media ($bp-ml) {
    font-size: 64px;
  }
  @media ($bp-l) {
    font-size: 72px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

h2 {
  font-size: 44px;
  margin: 24px 0;
  @media ($bp-ml) {
    font-size: 48px;
  }
}

h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  margin: 24px 0;
  @media ($bp-ml) {
    font-size: 32px;
  }
}

.title {
  max-width: var(--max-width);
}

p {
  line-height: 1.4;
  margin: 0 0 16px;
  font-weight: 500;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

// Custom cursor
body {
  // Arrow
  cursor: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="28px" height="auto" viewBox="0 0 188 296"%3E%3Cg fill="none"%3E%3Cpath fill="%23FFF" d="M8 0H1v265h31v-16h15v-16h3l13 1v-14l1-2 2 1 10-1h2v2 29h16v31h15v16h32v-16h15v-31h-15v-2-29h-16v-2-13h2l60-1v-31h-15v-2-13h-16v-2-14h-15v-2-14h-16v-2-13h-15v-2-14H94v-2-13H79v-2-14H63v-2-13H47v-2-14H32v-2-13H16v-2V0H8z"/%3E%3Cpath fill="%231C1C1C" d="M1 0h15v16h16v15H16v218h16v-16l15 1v15H32v16H1v-92V0z"/%3E%3Cpath fill="%231C1C1C" d="M32 31h15v16h16v15h16v16h15v15h16v16h15v15h16v16h-16v-15h-15v-16H94V93H78V78H63V62H47V47H32V31zm109 109h15v16h16v15h15v31l-62 1v15h-15v-31h62v-16h-16v-15h-15v-16zm-78 62h15v16H64l-1 16-15-1-1-15h16v-16zm15 16h16v31h16v31h31v16h-32v-16H94v-31H78v-31zm47 0h16v31h15v31h-15v-31h-16v-31z"/%3E%3C/g%3E%3C/svg%3E'), '/images/icons/arrow.cur', auto;
}

a:hover, .hamburger:hover, .navigation li:hover {
  // Hand
  cursor: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" version="1" viewBox="0 0 266 344"%3E%3Cg fill="none" fill-rule="nonzero"%3E%3Cpath fill="%23FFF" d="M78 0v16H63v140H47v-2-13H0v46h16v2 14h15v31h16v31h16v2 29h15v47h156v-47h15l1-44v-2h15v-50-60h-15l-1-2v-13h-15v-2-14h-31v-2-13h-47v-2-14h-31v-2-60a995 995 0 0 0-14 0h-2v-2V0H78z"/%3E%3Cpath fill="%231C1C1C" d="M78 0h31v16H78V0zM63 16h15v171H63v-15H47v-16h16V16zM109 16h16v62h31v16h47v15h31v16h15v15h16v110h-15l-1 46h-15v-47h15V141h-15v-16h-16v47h-15v-63h-32v47h-15V94h-31v62h-16V16zM0 141h47v15H16v31H0v-46zM16 187h15v16h16v31h16l-1 31H47v-31H31v-31H16v-16zM63 265h15v31h16v31h124v-31h16v47H78v-47H63v-31z"/%3E%3C/g%3E%3C/svg%3E'), '/images/icons/hand.cur', auto;
}

// Background color options
.bg-color-primary {
  background: linear-gradient(to right bottom, #2cacf6, #17b8fd) !important;
  color: var(--dark-blue);
  .icon, .title, .text {
    //color: var(--dark-blue) !important;
  }
}

.bg-color-secondary {
  background-color: var(--purple) !important;
}