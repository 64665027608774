@import "../../../sass-vars.scss";

.nav-button {
  &.open {
    .navigation {
      transform: none;
      opacity: 1;
      z-index: 90;
    }
    .hamburger {
      @media (hover: hover) {
        &:hover {
          transform: rotate(90deg) translateY(50%) scale(1.5);
        }
      }
    }
  }
}

.navigation {
  transform: translate(0%, -100%);
  opacity: 0.5;
  transition: all 400ms ease-in;
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--darker-blue);
  overflow: hidden;

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    width: 100%;
    max-width: 688px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: var(--light-blue);
      a {
        color: var(--dark-blue);
        text-shadow: 2px 2px 0px var(--medium-blue);
      }
    }
  }

  a {
    display: inline-block;
    width: 100%;
    color: var(--light-blue);
    text-decoration: none;
    font-size: calc(32px + 4vmin);
    font-family: var(--titles);
    @media ($bp-ml) {
      font-size: calc(32px + 6vmin);
    }
  }
}
