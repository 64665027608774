@import '../../../sass-vars.scss';

.language-selector {
  position: absolute;
  right: var(--pad);
  top: var(--pad);
  width: 72px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 300ms ease;
  z-index: 1;
  a {
    transition: all 250ms ease;
    font-size: 24px;
    line-height: 24px;
    text-decoration: none;
    color: var(--light-gray);
    @media (hover: hover) {
      &:hover {
        transform: scale(1.5);
        color: var(--light-blue); 
      }
    }
  }
  @media ($bp-l) {
    flex-direction: column;
    width: 48px;
  }
}
