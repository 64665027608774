@import 'sass-vars.scss';

.contact-form {
  width: 100%;
  padding: var(--pad);

  .inner {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 680px;
  }
  .title {
    width: 100%;
  }
  .image {
    width: 25%;
    margin-bottom: var(--pad);
    object-fit: cover;
  }
  .text {
    margin-bottom: var(--pad);
    flex-basis: calc(75% - var(--pad));
  }
}

.form.contact {
  display: flex;
  flex-direction: column;
  width: 100%;  

  label {
    display: none;
  }

  input, textarea, .button {
    font-family: var(--sans);
    &:focus {
      border: 3px solid var(--purple);
      &::placeholder {
        color: var(--light-gray);
      }
      outline: none;
    }
  }

  .button:focus {
    background-color: var(--purple);
  }

  input, textarea {
    height: 48px; 
    border-radius: 6px; 
    border: 2px solid var(--light-blue); 
    color: var(--light-gray);
    background: none;
    font-size: 16px;
    padding-left: 12px;
    margin-bottom: var(--pad);
    &::placeholder {
      color: var(--light-blue);
    } 
    @media ($bp-ml) {
      height: 56px;
    }
  }
  textarea {
    height: 96px;
    padding-top: 12px;
    @media ($bp-ml) {
      height: 112px;
    }
  }
  .button {
    margin-bottom: 0;
    line-height: 1;
    color: var(--light-gray); 
    border: 3px solid var(--purple);
    background: none; 
    font-size: 16px;
    @media (hover: hover) {
      &:hover {
        background: var(--purple);
      }
    }
  }
  .send-confirmation {
    color: var(--light-blue);
  }
}
