@import '../../../sass-vars.scss';

.header {
  .home-link {
    height: 48px;
    width: 48px;
    transition: all 250ms ease;
    position: absolute;
    z-index: 1;
    left: var(--pad);
    top: var(--pad);
    @media (hover: hover) {
      &:hover {
        transform: scale(1.2);
        g {
          stroke: var(--light-blue);
        }
      }
    }
  }

  .hamburger {
    width: 72px;
    height: 72px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    transition: all 250ms ease;
    bottom: 12px;
    z-index: 100;
    @media ($bp-ml) {
      bottom: 24px;
    }
    @media ($bp-l) {
      width: 80px;
      height: 80px;
      top: 12px;
    }
    .circle {
      opacity: 1;
      transition: opacity 400ms ease;
    }
    @media (hover: hover) {
      &:hover {
        transform: scale(1.5) translateX(-36%);
        .circle {
          opacity: 0;
        }
        g {
          fill: var(--light-blue);
        }
      }
    }

  }
}
