.button {
  white-space: nowrap;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 12px;
  border: 2px solid var(--light-blue);
  min-width: 216px;
  width: fit-content;
  transition: all 250ms;
  text-decoration: none;
  font-weight: 500;
  color: var(--light-gray);
  line-height: 24px;
  @media (hover: hover) {
    &:hover {
      background-color: var(--light-blue);
      color: var(--dark-blue);
    }
  }

  .icon-container {
    margin-left: 4px;
    flex-basis: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      width: 24px;
    }
  }

  .cta-text.with-icon {
    margin-left: 16px;
    margin-right: 8px;
  }

  @media (min-width: 800px) {
    font-size: 20px;
    height: 56px;
    min-width: 240px;
  }
}
