.notfound {
  display: flex;
  margin-top: 125px;

  .inner {
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;

    h1 {
      font-size: 200px;
    }
  }
}