@import "../../../sass-vars.scss";

.text-image {
  background: var(--dark-blue);

  // Layout
  .inner {
    margin: 0 auto;

    .text,
    .title,
    .cta-container {
      max-width: 680px;
      margin-left: auto;
      margin-right: auto;
    }

    // Titles styles
    h1.title {
      text-align: center;
      max-width: var(--max-width);
    }

    .two-columns {
      &.image-left,
      &.image-right {
        .title {
          margin-bottom: 16px;
        }
      }
    }
    @media ($bp-ml) {
      padding: 0 24px;
    }
  }

  // Image styles
  .image {
    grid-column: 1 / 3;
    min-height: 144px;
    height: 30vw;
    width: 100%;
    max-height: 288px;
    margin: var(--pad) 0;
  }

  // Button styles
  .cta-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 8px;
    @media ($bp-m) {
      justify-content: center;
    }
  }

  .button {
    margin-bottom: var(--pad);
    @media (min-width: 546px) {
      &:nth-child(2) {
        margin-left: 24px;
      }
    }
  }

  // Border styles

  &.border-color-primary,
  &.border-color-secondary {
    .inner {
      border: 16px solid;
      padding: 16px;
      @media ($bp-ml) {
        border-width: 24px;
        padding: 24px;
      }
      @media ($bp-l) {
        border-width: 32px;
        padding: 32px;
      }
      .button {
        border: none;
      }
    }
  }

  &.border-color-primary {
    .inner {
      border-color: var(--light-blue);
      .button {
        background: var(--light-blue);
        color: var(--dark-blue);
        &:hover {
          background-color: var(--purple);
          color: var(--light-gray);
        }
      }
    }
  }

  &.border-color-secondary {
    .inner {
      border-color: var(--purple);
      .button {
        background-color: var(--purple);
        color: var(--light-gray);
        &:hover {
          background: var(--light-blue);
          color: var(--dark-blue);
        }
      }
    }
  }

  // Background color overrides
  &.bg-color-primary, &.bg-color-secondary {
    margin-top: var(--pad);
  }

  &.bg-color-primary {
    .button {
      border: 2px solid var(--dark-blue);
      color: var(--dark-blue);
      @media (hover: hover) {
        &:hover {
          background-color: var(--dark-blue);
          color: var(--light-gray);
          .icon {
            color: var(--light-gray);
          }
        }
      }
    }
  }
}
