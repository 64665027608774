@import '../../../sass-vars.scss';
@import './BackgroundImage.scss';

.hero {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--dark-blue);
  margin-bottom: var(--pad);
  .inner {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 40vw;
    min-height: 264px;
    max-height: 576px;
    > div {
      width: auto !important;
    }
  }

  .content-wrapper {
    margin: auto;
  }

  .title {
    color: var(--light-gray);
    text-shadow: 2px 3px 7px #333;
    width: fit-content;
    text-align: center;
    margin: 0;
  }

  .subhead {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 48px;
  }

  .image {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .title,
  .image {
    animation: 1s ease 0s normal forwards 1 fadein;
    @keyframes fadein {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

// Landing page layout 
.page.landing-page .hero {
  .content-wrapper {
    max-width: 448px;
  }
  .subhead {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 20px;
    margin-bottom: 24px;
    max-width: 336px;
  }
  .title {
    font-size: 36px;
    margin-bottom: var(--pad);
  }
  .button {
    margin-left: auto;
    margin-right: auto;
    min-width: 192px;
  }
  @media ($bp-m) {
    .content-wrapper {
      max-width: 480px;
    }
    .title {
      font-size: 44px;
      margin-bottom: var(--pad);  
    }
    .subhead {
      max-width: 400px;
    }
  }
  @media ($bp-l) {
    .title {
      font-size: 56px;
    }
    .content-wrapper {
      max-width: 680px;
    }
    .subhead {
      max-width: 480px;
      margin-bottom: 32px;
      font-size: 22px;
    }
  }
  @media ($bp-lx) {
    .title {
      font-size: 64px;
    }
    .subhead {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }
}
