@import "../../../sass-vars.scss";

.text-image .inner.two-columns {
  width: fit-content;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: var(--pad);
  .image, 
  .title,
  .text,
  .cta-container {
    grid-column: 1 / 3;
  }

  @media ($bp-s) {
    &.image-left,
    &.image-right {
      .text {
        text-align: center;
      }
    }
  }

  &.image-left, &.image-right {
    .text {
      margin-bottom: 0;
    }
  }

  @media ($bp-ml) {
    &.image-left,
    &.image-right {
      .title {
        margin-left: 0;
      }
      .cta-container {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 700px) {
    &.image-left {
      .image {
        grid-column: 1 / 2;
        grid-row: 1 / 4;
      }
      .title,
      .text,
      .cta-container {
        grid-column: 2 / 3;
      }
    }

    &.image-right {
      .image {
        grid-column: 2 / 3;
        grid-row: 1 / 4;
      }
      .title,
      .text,
      .cta-container {
        grid-column: 1 / 2;
      }
    }
  }

  @media (min-width: 800px) {
    max-width: var(--max-width);
    .text {
      grid-column: auto / span 1;
      width: fit-content;
    }
  }
}
