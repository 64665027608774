// Background image special styling

.hero {
  &.background-image {
    background: hsla(241, 80%, 20%, 1);
    background-size: cover;
    background-position: center;
    background-blend-mode: soft-light;
    padding: 48px 16px 32px;
    @media ($bp-ml) {
      padding: 48px 24px;
    }
    @media ($bp-l) {
      padding: 72px 48px;
    }
    .image {
      color: var(--light-blue);
      position: relative;
      object-fit: contain;
      display: block;
      max-width: 1440px;
      filter: drop-shadow(2px 4px 8px black);
      height: 100%;
      width: 100%;
      margin: auto;
    }
  }
}
 
// Default layout only
.page.default .hero {
  &.background-image {
    .title {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      height: auto;
      transform: translateX(-50%);
      background: linear-gradient(
        to right bottom,
        var(--light-blue),
        var(--medium-blue)
      );
      color: transparent;
      background-clip: text;
      text-shadow: none;
      filter: drop-shadow(2px 4px 8px black);

      @media ($bp-s) {
        font-size: 10vw;
      }
      @media ($bp-ml) {
        font-size: 7vw;
      }
      @media ($bp-l) {
        font-size: 6vw;
      }
      @media ($bp-lx) {
        font-size: 6vmin;
      }
    }
  }
}
