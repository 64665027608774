@import '../../../sass-vars.scss';

.cards {
  margin-bottom: 24px;
  .card {
    color: var(--light-gray);
    padding: var(--pad);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid var(--light-blue);
    margin-bottom: var(--pad);

    .image-container {
      height: 0;
      width: 100%;
      position: relative;
      padding-top: 66%;
      overflow: hidden;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
    }

    .text {
      margin-bottom: var(--pad);
      @media ($bp-ml) and ($bp-l) {
        font-size: 18px;
      }
    }
    .title {
      flex-grow: 1;
      text-align: center;
      margin-bottom: 16px;
    }
    .button {
      width: 100%;
      background: none;
      border: 2px solid var(--light-blue);
      color: var(--light-gray);
      justify-content: center;
    }
    // Hover style
    @media (hover: hover) {
      .button:hover {
        background: var(--purple);
      }
    }

    @media ($bp-s), ($bp-lx) {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
    }

    @media ($bp-m) {
      display: grid;
      grid-template-columns: 33.3% 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-column-gap: var(--pad);
      .image-container {
        grid-column: 1 / 2;
        grid-row: 1 / 4;
        height: 100%;
        padding-top: 0;
      }
      .title {
        text-align: left;
      }
      .button {
        width: fit-content;
        justify-self: right;
      }
    }

    @media ($bp-l) {
      width: calc(33.3% - (var(--pad) / 2));
      margin-bottom: 0;
      .title {
        font-size: 26px;
      }
    }
  }
}
