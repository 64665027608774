.cardlist {
  background: var(--dark-blue);
  color: var(--light-gray);

  > .title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 48px;
  }

  .subhead {
    text-align: center;
    margin: 8px auto 40px;
    max-width: 720px;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    max-width: var(--max-width);
    margin: 0 auto;
    justify-content: space-between;
  }
}
