@import 'sass-vars.scss';

.text-image {
  .text {
    color: var(--light-gray);
    margin-bottom: 24px;
    a {
      color: var(--light-blue);
    }
  }

  // Remove top margin on H1 to avoid too much whitespace under hero
  h1 {
    margin-top: 0;
  }

  // Text size options
  .inner {
    @media ($bp-ml) {
      .text {
        font-size: 21px;
      }
    }
    &.small-text {
      max-width: unset;
      .title {
        font-size: unset;
      }
      .text {
        font-size: unset;
      }
    }
    &.large-text {
      .title {
        font-size: 44px;
      }
      .text {
        font-size: 20px;
      }
      @media ($bp-ml) {
        .text {
          font-size: 21px;
        }
      }
      @media ($bp-l) {
        .title {
          font-size: 48px;
        }
        .text {
          font-size: 24px;
        }
      }
    }
    // Styling for headers inline in wysiwyg text
    .text {
      h2 {
        font-size: 40px;
        @media ($bp-ml) {
          font-size: 36px;
        }
        @media ($bp-l) {
          font-size: 40px;
        }
      }
    }

    // List styling
    ul {
      line-height: 1.5;
      font-weight: 500;
    }
  }

  // Background color styling
  &.bg-color-primary {
    .title,
    .text {
      color: var(--dark-blue);
    }
  }
}
