@import 'sass-vars.scss';

.text-image {
  // Big bullets
  .bigbullets {
    ul {
      list-style: none;
      font-size: 21px;
      line-height: 40px;
      font-weight: bold;
      border: 4px solid var(--purple);
      color: var(--light-blue);
      padding: 16px;
      @media ($bp-ml) {
        font-size: 24px;
        padding: 24px;
      }
    }
    li {
      margin-left: 40px;
      position: relative;
    }
    li:before {
      position: absolute;
      left: -40px;
      top: 8px;
      content: '';
      float: left;
      background: var(--light-blue);
      mask-image: url('../../../images/icons/bulletpoint.svg');
      width: 24px;
      height: 24px;
    }
    &.two-columns {
      .text {
        width: 100%;
      }
      ul {
        margin-bottom: 0;
      }
      @media (min-width: 800px) {
        .text {
          width: fit-content;
        }
        li {
          margin-left: 36px;
          &:before {
            left: -36px;
          }
        }
      }
    }
  }
}