@import '../../../sass-vars.scss';

.footer {
  background: var(--dark-blue);
  padding: calc(2 * var(--pad));
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  border-top: 4px solid var(--purple);
  opacity: 0;
  animation: fadein 0.2s 2s forwards;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .contact-details {
    margin: auto;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .phone {
    color: var(--light-gray);
    margin-bottom: 8px;
  }
  .email {
    color: var(--light-gray);
  }
  .partner-logo {
    align-self: center;
    transform: scale(0.7);
    img {
      width: 103px;
      height: auto;
      display: inline-block;
    }
    span {
      text-decoration: none;
      color: white;
      font-size: 14px;
      position: relative;
      bottom: 4px;
      left: 4px;
    }
  }

  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: center;
    position: relative;

    .partner-logo {
      transform: scale(1);
      position: absolute;
      right: 48px;
      bottom: 70px;
      margin-top: 4px;
    }
  }
}
