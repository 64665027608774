@import "../../../sass-vars.scss";

.bullets-deck {
  padding: calc(var(--pad)*2) var(--pad) var(--pad);

  > .title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }
}

.bullets {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: var(--max-width);
  margin: 0 auto;
  @media ($bp-lx) {
    padding-top: 32px;
  }
}

.bullet {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  border: 4px solid var(--purple);
  margin-bottom: var(--pad);
  color: var(--light-blue);
  padding: 24px;

  .title-container {
    display: flex;
    align-items: center;
  }

  .icon-container {
    width: 25px;
    margin-right: 12px;
    color: var(--light-blue);
    line-height: 1;
    @media ($bp-ml) {
      width: 30px;
      margin-right: 16px;
    }
  }

  .title {
    margin: 12px 0;
  }

  @media (min-width: 720px) {
    flex-basis: calc(50% - (var(--pad) / 2));
  }
  @media ($bp-lx) {
    flex-basis: calc(50% - 16px);
    margin-bottom: 32px;
  }
}
